<template>
  <Modal
    id="migrated-notice-modal"
    ref="modal"
    :width="700"
    disable-link
    :close-button="false"
    :background-close="true"
    :cta="ctaClick"
    :cta-text="brandCom.primaryButton.text"
    :cancel="!!brandCom.secondaryButton"
    :cancel-function="secondaryClick"
    :cancel-text="brandCom.secondaryButton?.text"
    prevent-esc-close
  >
    <template #image>
      <ImageComponent
        v-if="imagePath"
        class="campaign__img"
        :src="imagePath"
        :alt="brandCom.altText"
      />
    </template>

    <h1 :id="titleId" class="title">{{ brandCom.title }}</h1>
    <p :aria-labelledby="titleId" v-html="brandCom.body"></p>
  </Modal>
</template>

<script>
import { uniqueId } from 'src/lib/helpers'
import ImageComponent from 'src/components/common/image/image.vue'
import Modal from 'src/components/common/modal/campaign_modal.vue'
import communication from './communication.json'

export default {
  name: 'MigratedNoticeModal',
  components: { ImageComponent, Modal },
  data() {
    return {
      timeout: undefined,
      titleId: `modal-title-${uniqueId()}`,
      brandCom: undefined
    }
  },
  computed: {
    imagePath() {
      if (this.$breakpoint.upToTablet()) {
        return this.brandCom.imageSmall
      } else {
        return this.brandCom.imageLarge
      }
    }
  },
  created() {
    this.$bus.on('open-migrated-notice', option => {
      this.open(option)
    })
    this.getBrandCom()
  },
  beforeUnmount() {
    this.$bus.off('open-migrated-notice', this.open)
  },
  methods: {
    open(option) {
      if (option === 'typo') {
        this.brandCom = communication.migrated.fonic.typo
        this.$store.commit('authentication/resetRaittRef')
      } else {
        this.brandCom = this.$brand.isFonic()
          ? communication.migrated.fonic.default
          : communication.migrated.lidl.default
      }

      this.$refs.modal.open()
    },
    ctaClick() {
      const linkPath = this.brandCom.primaryButton.url

      if (linkPath) {
        if (linkPath.startsWith('/')) {
          this.$router.push(linkPath)
        } else {
          const url = new URL(linkPath)

          window.location.href = url.href
        }
      }
    },
    secondaryClick() {
      const linkPath = this.brandCom.secondaryButton.url

      if (linkPath) {
        if (linkPath.startsWith('/')) {
          this.$router.push(linkPath)
        } else {
          const url = new URL(linkPath)

          window.location.href = url.href
        }
      }
    },
    getBrandCom() {
      this.brandCom = this.$brand.isFonic()
        ? communication.migrated.fonic.default
        : communication.migrated.lidl.default
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign__img {
  justify-content: center;
  overflow: visible;
  border-radius: 6px 6px 0 0;

  :deep(img) {
    margin: -25px;
    min-width: calc(100% + 42px);
  }

  @include breakpoint($up-to-tablet) {
    :deep(img) {
      min-width: 100vw;
    }
  }
}

.campaign__text-content {
  margin-top: 40px;
  max-height: calc(90vh - 450px);
  overflow-y: scroll;
}

.title {
  line-height: 1.3;
}

:deep(.modal__inner-content) {
  margin-top: 30px;
}

:deep(.modal__inner-content_wrapper) {
  max-height: calc(90vh - 400px);
}
</style>
